.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Particles {
  position: fixed;
  z-index: -1;
  min-height: 100vh;
  width: 100vw;
  background-color: #f5f0db;
}
